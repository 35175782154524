import { customRef } from 'vue'

//Funcion con debounce
/**
 * ej de uso:
 * @param {Function} fn 
 * @param {int} delay 
 * @returns 
 * Ej de uso: (No hace falta importar esta funcion)
 * const onInputBuscador = debounce(( val ) => {
 *  console.log({val});
 *  buscadorProductos(val);
 * },500) 
 */
export function debounce (fn, delay) {
  let timeout

  return (...args) => {
    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}
//variable debounced
export function useDebouncedRef (value, delay = 500) {
  let timeout
  return customRef((track, trigger) => {
    return {
      get () {
        track()
        return value
      },
      set (newValue) {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          value = newValue
          trigger()
        }, delay)
      },
    }
  })
}
export function convertToUrlFriendly (texto) {
  // Elimina caracteres especiales y acentos
  const encodedText = encodeURIComponent(texto);
  // Reemplazar caracteres especiales con guiones bajos
  const urlAmigable = encodedText.replace(/%20/g, '+'); // Reemplazar espacios por guiones bajos
  
  return urlAmigable.toLowerCase();
}

export function formatPrice (number) {
  const numero = parseFloat(number)
  return numero.toLocaleString('es-ES', {
    minimumFractionDigits: 2, // Establecer 2 decimales
    maximumFractionDigits: 2,  // Establecer 2 decimales
  });
}
// Funcion para extraer la parte decimal de un string con formato de moneda 
// Ejemplo: "100,00" -> "00". Sirve con la funcion de arriba -> 'formatPrice'
export function extractDecimalPart (formatPriceString) {
  const parts = formatPriceString.split(',');
  if (parts.length === 2) {
    return parts[1];
  }
  return '00'; // Si no hay parte decimal, devolvemos "00" por defecto
}
export function formatPriceSup (number, cssClass='') {
  if(number == 0 || number == 0.00){
    return `$ 00 <sup class="inline underline ${cssClass}">00</sup>`;
  }
  if(!number){ return ''}

  if(number.toString().includes('.') && number.toString().includes(',')){
    return number
  }
  const price = formatPrice(number);
  const parts = price.split(',');
  if (parts.length === 2) {
    return `$ ${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')} <sup class="inline underline ${cssClass}">${parts[1]}</sup>`;
  }
  return `$ ${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')} <sup class="inline underline ${cssClass}">00</sup>`;
  
}

export function formatearNumeroConPuntos (numero) {
  return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
/**
 * Funcion para no permitir valores que no sean letras: (A-Z, a-z)
 * Se debe colocar en un evento de un input como:@input, @keypress, @keyup.
 @input = "return (((event.charCode >= 65 && event.charCode <= 90 ) || (event.charCode >= 97 && event.charCode <= 122)))"
 @keypress = "return (((event.charCode >= 65 && event.charCode <= 90 ) || (event.charCode >= 97 && event.charCode <= 122)))"
 @keyup= "return (((event.charCode >= 65 && event.charCode <= 90 ) || (event.charCode >= 97 && event.charCode <= 122)))"
 * return (((event.charCode >= 65 && event.charCode <= 90 ) || (event.charCode >= 97 && event.charCode <= 122)))
 * 
 * Para solo numeros
 * onkeypress="return ((event.charCode >= 48 && event.charCode <= 57))"
 */

export function formatDate(date) {
    // Obtiene el año
    const year = date.getFullYear();
    
    // Obtiene el mes y le suma 1 porque los meses en JavaScript van de 0 a 11
    const month = String(date.getMonth() + 1).padStart(2, '0');
    
    // Obtiene el día del mes
    const day = String(date.getDate()).padStart(2, '0');
    
    // Retorna la fecha formateada
    return `${year}-${month}-${day}`;
}